<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:title>
      <div style="display: flex; justify-content: flex-start; margin: auto">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="mt-1" v-bind="attrs" v-on="on">
              <v-icon left large> mdi-cash-register </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in cashList" :key="item.value" @click="cashbox = item.value">
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-card-text v-if="balanceCash" style="font-size: 0.85rem; line-height: 1rem" class="py-0">
          <span v-if="balanceCash.date_doc">
            Начало периода: {{ balanceCash.date_doc }}. Сумма: {{ balanceCash.value_start }}
            <br />
          </span>

          <span>За период. Приход: {{ balanceCash.value_in }}; Расход: {{ balanceCash.value_out }}; Остаток: {{ balanceCash.value_end }}</span>
          <br />
          <span style="color: green"
            >За день. Приход: {{ balanceCash.day_value_in }}; Расход: {{ balanceCash.day_value_out }}; Начало дня: {{ balanceCash.day_value_start }}</span
          >
        </v-card-text>
        <v-select
          v-if="false"
          outlined
          :items="cashList"
          item-text="text"
          item-value="value"
          v-model="cashbox"
          label="Касса"
          hide-details
          dense
          class="my-1"
          style="max-width1: 500px; widt1h: 70%"
        />
      </div>
    </template>
    <template v-slot:actions>
      <a-btn-config :title="'Параметры'" v-model="paramsValue" :model="paramsModel" size="md" @onConfig="setParams($event)" />
      <a-btn-refresh @click="$refs.table.updateData()" size="xs" title="Обновить" />
      <a-btn-add :icon="'far fa-plus-square'" :title="'Приход'" size="md" v-if="getAccess('documents.cashIn.create')" @click="createNew(1)" />
      <a-btn-add :icon="'far fa-minus-square'" :title="'Расход'" size="md" v-if="getAccess('documents.cashOut.create')" @click="createNew(2)" />
      <a-btn-add :icon="'mdi-import'" title="Импорт" size="md" :disabled="!cashbox" v-if="getAccess('documents.cashOut.create')" @click="dialogImport = true" />
    </template>
    <portal to="v-main">
      <CashDialog ref="cashDialog" @updateData="$refs.table.updateData()" />
      <select-dialog v-model="showSelectDialog" v-if="showSelectDialog" :operations="operations" @select="onSelectOperation($event)" />
      <a-btn-import v-model="dialogImport" :uploadApi="`/accounting/import/orders/` + cashbox" @import="onImport($event)" />
    </portal>
    <portal to="v-add">
      <v-card-text v-if="balanceCash && false" style="font-size: 0.85rem; line-height: 1rem">
        <span v-if="balanceCash.date_doc">
          Начало периода: {{ balanceCash.date_doc }}. Сумма на начало: {{ balanceCash.value_start }}
          <br />
        </span>
        <span>Приход: {{ balanceCash.value_in }}; Расход: {{ balanceCash.value_out }} </span>
        <br />
        <span>Остаток в кассе: {{ balanceCash.value_end }} </span>
      </v-card-text>
    </portal>
    <a-table-f-api
      v-if="cashbox"
      ref="table"
      :api="url"
      :model="getModelList(m)"
      :useQuery="false"
      :defaults="defaults"
      @click="onClickRow($event)"
      @rowContextMenu="onRowContextMenu($event)"
      @update-data="getBalance($event.filters)"
    >
      <template v-slot:footer1>
        <div class="f-table-footer">
          <div class="f-table-row">
            <div style="margin: 6px 6px" v-if="$refs.table">
              Показано: {{ $refs.table.pager.count }}, На сумму: {{ ($refs.table.data.total_price || 0).toLocaleString() }}
            </div>
          </div>
        </div>
      </template>
    </a-table-f-api>
    <v-card-text v-else> Необходимо выбрать кассу</v-card-text>
    <context-menu ref="rowContextMenu" :items="contextMenuItems" @clickData="onContextMenuClick($event)" />
  </static-fullscreen-card>
</template>

<script>
import { getAccess, genModel, keyDetect } from "@/components/mixings";
import libsCash from "./libsCash";

export default {
  mixins: [getAccess, genModel, libsCash, keyDetect],
  components: {
    CashDialog: () => import("./dialogs/cashDialog"),
    selectDialog: () => import("./dialogs/cashOperationSelectDialog"),
  },
  data() {
    return {
      paramsModel: [{ name: "importOnly", title: "Показать только импорт", type: "switcher", default: 0 }],
      paramsValue: {},
      idEdit: 0,
      showEditDialog: false,
      showSelectDialog: false,
      cashList: null,
      cashbox_: null,
      balanceCash: null,
      title: "",
      m: this.$store.getters["config/get"].models.cashBook,
      operation: null,
      defaults: {
        filters: {},
        sort: { key: "date_doc", order: "DESC" },
        paramName: "documentCashBook",
      },
      cashType: null,
      dialogImport: false,
      configDoc: null,
      balance: 0,
    };
  },
  created() {
    this.$root.title = this.m.title + (this.cashName ? "- " + this.cashName : "");
    this.getCashList();
  },
  computed: {
    cashName() {
      let n = (this.cashList || []).find((c) => c.value == this.cashbox)?.text || "";
      this.$root.title = this.m.title + (n ? "- " + n : "");

      return n;
    },
    cashbox: {
      get() {
        return this.cashbox_;
      },
      set(v) {
        this.setCashBox(v);
        this.cashPeriod = [];
        //this.getCashPeriod();
      },
    },
    url() {
      if (!this.cashbox) return null;
      return this.m.api + "/" + this.cashbox;
    },

    permit() {
      return this.getAccess("menu.documentCashOut");
    },
    contextMenuItems() {
      let res = [];
      if (this.$root.profile.role == 1000) res = [{ name: "reStatus2", title: `Перепровести` }];
      return res;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.cashType = null;
        if (this.idEdit) this.$refs.table.updateRowData(this.idItemShow);
        else this.$refs.table.updateData();
        this.idEdit = 0;
        this.getBalance();
      }
    },
    dialogImport() {
      this.$refs.table.updateData();
      this.getBalance();
    },
    cashbox_(v) {
      if (v) this.getBalance();
    },
  },
  methods: {
    setParams(v) {
      console.log("setParams", v);
      const f = Object.assign({}, this.defaults.filters);
      if (v?.importOnly == 1) {
        f.import_id = { condition: "is not", value: null };
      } else {
        f.import_id = undefined;
      }
      this.defaults.filters = f;

      // this.$refs.table.updateData();
    },
    setCashBox(v) {
      this.$store.commit("config/setLocalParams", {
        name: "orderCashBook.cashBox",
        value: v,
      });
      this.cashbox_ = v;
    },
    async onImport(e) {
      console.log(e);
      return;
      let data = { file: e.data.file };
      await this.$axios.post("/accounting/import/orders", data);
    },
    async getCashList() {
      const resp = await this.$axios.get("accounting/dir/cashbox/select");
      this.cashList = [...resp.data.data];
      this.cashbox_ = this.$store.getters["config/getLocalParams"]?.["orderCashBook.cashBox"] || null;
    },
    async onContextMenuClick(e) {},
    onRowContextMenu(e) {
      console.log(e.row.status);
      if (e.row.status == 2 && this.$root.profile.role == 1000) {
      } else return;
      this.contextData = e;
      this.$refs.rowContextMenu.showMenu(e.event, e.row);
    },

    async onSelectOperation(e) {
      console.log("select", e);
      if (!e.value) return;
      this.$refs.cashDialog.openDialog(e.id, 0, {}, { cashbox_id: this.cashbox, operation_name: e.text });
    },
    async onClickRow(d) {
      let type = d.row.operation_type;
      if (!type) {
        type = d.row.model == "ПКО" ? "AccountingDocOrderReceiptModel" : d.row.model == "РКО" ? "AccountingDocOrderWithdrawalModel" : null;
      }
      console.log(type);
      this.$refs.cashDialog.openDialog(type, d.row.id);
    },
    createNew(v) {
      let m = {};
      this.operations = [];
      if (v == 1) m = this.$store.getters["config/get"].models.cashIn;
      if (v == 2) m = this.$store.getters["config/get"].models.cashOut;

      this.operations = m.config.map((e) => {
        return e.id;
      });
      this.showSelectDialog = true;
    },
    async getBalance() {
      this.balanceCash = null;
      let n = (this.cashList || []).find((c) => c.value == this.cashbox)?.text || "";
      this.$root.title = this.m.title + (n ? " - " + n : "");

      if (!this.cashbox) return;
      let resp;
      let b = null;
      resp = await this.$axios.post("/report_sql", { name: "getBalanceCashBox", params: { id: this.cashbox } });
      if (resp.data.status == "ok") {
        b = resp.data.data[0];
      }
      const d = new Date(b.date_doc);
      let v_in = b.value_in;
      let v_out = b.value_out;

      b.date_doc = b.date_doc && d.toLocaleDateString("ru-RU") + " " + d.shorttime;
      b.value_in = b.value_in && b.value_in.toLocaleString("ru-Ru");
      b.value_out = b.value_out && b.value_out.toLocaleString("ru-Ru");
      b.value_in += v_in !== b.acc_in ? "*" : ``;
      b.value_out += v_out !== b.acc_out ? "*" : ``;
      b.value_start = b.value_start && b.value_start.toLocaleString("ru-Ru");
      b.value_end = b.value_end && b.value_end.toLocaleString("ru-Ru");
      b.day_value_in = (b.day_value_in && b.day_value_in.toLocaleString("ru-Ru")) || 0;
      b.day_value_out = (b.day_value_out && b.day_value_out.toLocaleString("ru-Ru")) || 0;
      b.day_value_start = b.day_value_start && b.day_value_start.toLocaleString("ru-Ru");
      this.balanceCash = Object.assign({}, b);
    },
  },
};
</script>
